import { createStore } from 'redux'

const initialState = {
  alarmCnt:  0,
  alarmList: [],
  isMobile:  false,
  pathname:  '',
}
  
const changeState = (state = initialState, action) => {
  switch (action.type) {
    case 'updateIsMobile':
      return {
        ...state,
        isMobile: action.isMobile
      };
    case 'updatePathname':
      return {
        ...state,
        pathname: action.pathname
      };
    case 'updateAlarm':
      return {
        ...state,
        alarmCnt:  action.alarmCnt,
        alarmList: action.alarmList ? action.alarmList : state.alarmList
      };
    default:
      return state
  }
}

const store = createStore(changeState)
export default store
