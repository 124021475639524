import 'react-app-polyfill/stable'
import 'core-js'
import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { createRoot } from 'react-dom';

import { Provider } from 'react-redux'
import store from './store'
import './i18n';

// const root = createRoot(document.getElementById('root'));  // createRoot로 root 생성

// root.render(
//   <Provider store={store}>
//     <App />
//   </Provider>
// );


ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);
reportWebVitals()





